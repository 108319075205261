import { useContext } from 'react';
import { CategoryContext } from '../../context/CategoryContext';
import { CurrentCatContext } from '../../context/CurrentCatContext';
import { ActiveContext } from '../../context/ActiveContext';
import { HashLink } from 'react-router-hash-link';
import { FaSortUp } from 'react-icons/fa';

const CategoryItems = () => {
  const { categories } = useContext(CategoryContext);
  const { setCurrCat } = useContext(CurrentCatContext);
  const { activeBtn, setActiveBtn } = useContext(ActiveContext);

  const handleCategory = (e) => {
    // Filter array on click
    const catValue = e.target.value;

    const filterCategory = categories.filter((ele) => {
      return ele.Name === catValue;
    });

    setActiveBtn(catValue);

    setCurrCat(filterCategory);
  };

  return (
    <>
      <div className="template-btns" id="templateBtn">
        {categories.map((val, k) => {
          const { Name } = val;
          return (
            <div key={k}>
              <HashLink to="/#templateSection">
                <button
                  id={k}
                  value={Name}
                  className={`btn category-name ${activeBtn === Name ? 'active-item' : ''}`}
                  onClick={handleCategory}>
                  <FaSortUp />
                  {Name}
                </button>
              </HashLink>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CategoryItems;
