import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// import Header from "./components/shared/Header";
// import Footer from "./components/shared/Footer";
import Home from './components/Home';
import Feature from './components/Feature';
// import ContactUs from "./components/ContactUs";
import ContactForm from './components/ContactForm';

import Layout from './components/layout/Layout';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './styles/style.scss';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/ContactUs" component={ContactForm} />
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/Feature" component={Feature} />
            <Redirect to="/" />
          </Switch>
        </Layout>
      </Switch>
    </Router>
  );
};

export default App;
