import {
  fashionOne,
  fashionTwo,
  fashionThree,
  fashionFour,
  AccuLab,
  CakesBakes,
  KipsPublication,
  SultanBakers,
} from '../assets/images/landing/landingimages';

const Categories = [
  {
    Name: 'Fashion',
    id: 1,
    Detail: [
      {
        Heading: 'Fashion Template',
        SubHeading: 'clothing',
        Image: fashionOne,
      },
      {
        Heading: 'Simple and clean',
        SubHeading: 'clothing',
        Image: fashionTwo,
      },
      {
        Heading: 'Cosmetics',
        SubHeading: 'beauty',
        Image: fashionThree,
      }
    ],
  },
  {
    Name: 'Bakery',
    id: 2,
    Detail: [
      {
        Heading: 'Cakes & Bakes',
        SubHeading: 'Bakery',
        Image: CakesBakes,
      },
      {
        Heading: 'Sultan Bakers',
        SubHeading: 'Bakery',
        Image: SultanBakers,
      },
    ],
  },
  {
    Name: 'Education',
    id: 3,
    Detail: [
      {
        Heading: 'Kips Publication',
        SubHeading: 'Books',
        Image: KipsPublication,
      },
    ],
  },
  {
    Name: 'Labs & Test',
    id: 4,
    Detail: [
      {
        Heading: 'ACCU Lab',
        SubHeading: 'Test & Lab',
        Image: AccuLab,
      },
    ],
  },
  {
    Name: 'Electronics',
    id: 5,
    Detail: [
      {
        Heading: 'Electronics',
        SubHeading: 'Electronics',
        Image: fashionFour,
      },
    ],
  },
   
];

export default Categories;
