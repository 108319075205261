import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../../assets/images/logo/logo.png";

const Header = (props) => {
  return (
    <>
      <div className="container">
        <h2>{props.title}</h2>
        <div className="navbar-main">
          <nav className="navbar navbar-expand-lg navbar-light">
            <NavLink className="nav-brand-text navbar-brand" to="/">
              <img src={logo} alt="logo img" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="navbar-collapse collapse in"
              id="navbarNavAltMarkup"
            >
              <div className="navbar-nav ml-auto">
                <NavLink className="nav-item nav-link" to="/Feature">
                  Features
                </NavLink>
                <HashLink to="/#templateSection" className="nav-item nav-link">
                  Templates
                </HashLink>
                <NavLink className="nav-item nav-link" to="/ContactUs">
                  Pricing
                </NavLink>
                {/* <NavLink className="nav-item nav-link" to="/">
                    Contact Us
                  </NavLink> */}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
