import React from "react";
import { Link } from "react-router-dom";
import logoft from "../../assets/images/logo/logo-ft.png";
import SocialData from "../../data/SocialData";
import { contactImg } from "../../assets/images/landing/landingimages";
import CategoryItems from "../TemplateCategory/CategoryItems";

const Footer = () => {
  return (
    <>
      <section className="contact-section white-bg section-pt">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="head-mb text-pt">
                <h1 className="head-md-text text-left">
                  What are you waiting for
                </h1>
                <p>Contact us for more information or get Quote now.</p>
              </div>
              <div>
                <Link to="/ContactUs">
                  <button className="btn btn-green">Let us know</button>
                </Link>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <div className="right-img-position">
                <img src={contactImg} alt="running img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="text-center">
            <div className="nav-brand-text">
              <img src={logoft} alt="footer logo img" className="logo-ft" />
            </div>

            <div className="row">
              <div className="col-md-12 footer-btns">
                <CategoryItems />
              </div>
            </div>

            {SocialData.map((val, i) => {
              return (
                <span key={i}>
                  <a href={val.link} rel="noreferrer" target="_blank">
                    <span className="social-icon">{val.Icon}</span>
                  </a>
                </span>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
