import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { useDispatch, useSelector } from 'react-redux';
import { setPhoneNumber } from '../../redux/actions/actions';
import './phoneTextField.css';
const PhoneTextField = (props) => {
  const dispatch = useDispatch();
  const { PhoneNumber } = useSelector((state) => state.Phone);

  const onPhoneNumberChange = (isValid, rawValue, countryData, formattedValue, extension) => {
    if (isValid) {
      dispatch(setPhoneNumber({ Value: formattedValue.toString().replace(/\s/g, ''), Error: '' })); 
    } else {
      dispatch(setPhoneNumber({ Error: 'Enter Valid Number' })); 
    }
  };
  return (
    <>
    <label>Phone</label>
      <IntlTelInput
        containerClassName="intl-tel-input"
        inputClassName="form-control tel-input"
        preferredCountries={['PK', 'US', 'GB']}
        autoComplete="off"
        separateDialCode="true"
        name="phone"
        defaultValue={props.value === null ? '' : props.value}
        onPhoneNumberChange={onPhoneNumberChange}
      />
      <label id="error-div" style={{ color: 'red' }}>
        {PhoneNumber.Error !== undefined ? PhoneNumber.Error : ''}
      </label>
    </>
  );
};

export default PhoneTextField;
