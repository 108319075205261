import { actionTypes } from '../actions/actionTypes';

const initialState = {
  PhoneNumber: {
    Value: null,
    Error: null,
  },
};

export const setPhoneNumberState = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.PHONE_NUMBER_STATE:
      return { PhoneNumber: { ...state.PhoneNumber, ...payload } };
    default:
      return state;
  }
};
