import { createContext, useState } from "react";
import Categories from "../data/TemplateData";

export const CategoryContext = createContext(null);

export const CategoryProvider = (props) => {
  const categoryNames = Categories.map((item) => {
    return item;
  });

  const [categories, setCategories] = useState(categoryNames);

  return (
    <CategoryContext.Provider value={{ categories, setCategories }}>
      {props.children}
    </CategoryContext.Provider>
  );
};
