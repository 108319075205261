import { Form, Input } from 'antd';
import 'antd/dist/antd.css';
// import './textfieldview.css';
const TextFielInput = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.name}
      rules={[
        {
          required: true,
          message: props.requiredError,
        },
      ]}>
      <Input placeholder={props.placeholder} type={props.type} />
    </Form.Item>
  );
};

export default TextFielInput;
