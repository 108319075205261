import React from 'react';
import { Form, Select } from 'antd';
import 'antd/dist/antd.css';
const { Option } = Select;

export const SearchSelectBox = (props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.name}
      rules={[
        {
          required: true,
          message: props.requiredError,
        },
      ]}>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        // filterSort={(optionA, optionB) =>
        //   optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        // }
      >
        {props.options.map((option, index) => {
          return (
            <Option value={option} key={index}>
              {option}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
