import React from "react";
import { FaCircle } from "react-icons/fa";

const FeatureAssistance = (props) => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6 text-center">
            <h1 className="head-feature-text">{props.name}</h1>
            <p className="assist-desc">{props.desc}</p>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row mt-ml">
          {props.detail.map((detail, ind) => {
            return (
              <div key={ind} className="col-md-4">
                <div className="">
                  <div className="head-sm-text">
                    <span className="circle-icon">
                      <FaCircle />
                    </span>
                    {detail.Heading}
                  </div>
                  <div>{detail.HeadDescription}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FeatureAssistance;
