// import TemplateData from "../data/TemplateData";
// import { FaSortUp } from "react-icons/fa";

import {
  businessImg,
  serviceImg,
  clientImg,
  ecomImg,
  reportImg,
  userImg,
  appImg,
  cbBrandImg,
  kipsBrandImg,
  techIcon,
  designIcon,
  marketIcon,
} from "../assets/images/landing/landingimages";
import { Link } from "react-router-dom";
import CategoryItems from "./TemplateCategory/CategoryItems";
import CategoryDetail from "./TemplateCategory/CategoryDetail";

const Home = () => {
  return (
    <>
      {/* Home Section */}
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="banner-inner">
                <div className="banner-left">
                  <h1>Build & grow your online business with Retailry</h1>
                  <div>
                    <p>
                    Set up and expand your business online with Retailry’s efficient and powerful 
                    e-commerce system. Retailry offers you a software in cloud that takes care of all 
                    your e-commerce needs anywhere, anytime, so you can take your 
                    business to the next level by focusing on the most important component, the product.
                    </p>
                  </div>
                  <div>
                    <Link to="/ContactUs">
                      <button className="btn btn-green">View Plans</button>
                    </Link>
                    {/* <button className="btn btn-white ml-3">
                        Start free trail
                      </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <div className="">
                <img
                  src={businessImg}
                  alt="business img"
                  className="width-full img-padding"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Home Section End */}

      {/* Service Section */}
      <section className="section-padding white-bg mob-text-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 head-mb">
              <h1 className="head-md-text">
                We are your E-commerce service
                <br /> providers, powering your business with
              </h1>
            </div>
            <div className="row justify-cntr-mob">
              <div className="col-md-5 display-col-mob">
                <img
                  className="img-display-mob"
                  src={serviceImg}
                  alt="ecom service"
                />
              </div>
              <div className="col-md-1 display-col-mob"></div>
              <div className="col-md-6">
                <div className="small-text-div">
                  <div>
                    <img src={techIcon} alt="tech icon" />
                  </div>
                  <div>
                    <h5 className="small-head-text">
                      Sophisticated Technology
                    </h5>
                    <p>
                    Our cutting-edge technology guarantees smooth 
                    functioning of all processes, optimum network speed, 
                    data and user security, trouble-free customization, and a lot more.
                    </p>
                  </div>
                </div>

                <div className="small-text-div">
                  <div>
                    <img src={designIcon} alt="graphic icon" />
                  </div>
                  <div>
                    <h5 className="small-head-text">Experiential Design</h5>
                    <p>
                    Our design ensures a truly hassle-free user experience and empowers you 
                    to leave an enduring mark on your clientele.
                    </p>
                  </div>
                </div>

                <div className="small-text-div">
                  <div>
                    <img src={marketIcon} alt="market icon" />
                  </div>
                  <div>
                    <h5 className="small-head-text">Strategic Marketing</h5>
                    <p>
                    Achieve business success with increased sales through our marketing features 
                    such as social media integration and loyalty programs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service Section End */}

      {/* Feature Section */}
      <section className="section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 head-mb">
              <h1 className="head-md-text">What we are providing</h1>
              <p className="text-center">
              Boost your sales with us, and witness your business achieve greater heights, like never before!
              </p>
            </div>

            <div className="row text-center">
              <div className="col-md-6">
                <div className="small-text-service">
                  <div><img src={ecomImg} alt="ecom" /></div>
                  <div className="service-info">
                    <h5 className="small-head-text">
                      Complete
                      <br /> E-Commerce Solution
                    </h5>
                    <p>
                    We help you grow your business online by providing a platform with all the e-commerce features 
                    to create an e-store to start, run, and scale your business.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="small-text-service">
                  <div><img src={reportImg} alt="ecom" /></div>
                  <div className="service-info">
                    <h5 className="small-head-text">Analytics & <br />Reports</h5>
                    <p>
                    Take your business to unprecedented heights by gathering and analyzing important data related 
                    to your products, sales, customers, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="small-text-service">
                  <div><img src={userImg} alt="ecom" /></div>
                  <div className="service-info">
                    <h5 className="small-head-text">
                      Functional
                      <br /> User Engagement
                    </h5>
                    <p>
                    We help you multiply your customer base and keep your customers loyal by providing 
                    them a matchless user experience, enhancing your presence on social media, and offering 
                    them different incentives.   
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="small-text-service">
                  <div><img src={appImg} alt="ecom" /></div>
                  <div className="service-info">
                    <h5 className="small-head-text">Mobile <br />App</h5>
                    <p>
                    Capture the ever growing mobile audience through our robust mobile app customized for your needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature Section End */}

      {/* Clients Section */}
      <section className="section-pt client-section white-bg mob-text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="head-mb text-pt">
                <h1 className="head-md-text text-left">
                  Some of our clients
                </h1>
                <p>
                Our clients include some of the most prominent enterprises in the country.
                </p>
              </div>
              <div className="brands-img">
                <img src={cbBrandImg} alt="cakes bakes" />
                <img src={kipsBrandImg} alt="kips pb" />
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4">
              <div className="right-img-position">
                <img src={clientImg} alt="our clients" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Clients Section End */}

      {/* Template Section */}
      <section
        id="templateSection"
        className="template-section section-padding green-bg"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 head-mb">
              <h1 className="head-md-text">Get Started</h1>
              <p className="text-center">
              Get in touch and take the first step towards revolutionizing your online business.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <CategoryItems />
            </div>
          </div>

          <CategoryDetail />

          <div className="row display-none-mob">
            {/* <div className="col-md-5">
              Boost your sales with us, and witness your business achieve
              greater heights, like never before!
            </div> */}
            <div className="col-md-7"></div>
            {/* <div className="col-md-2">
                <button className="btn explore-btn btn-white">
                  Explore more
                </button>
              </div> */}
          </div>
        </div>
      </section>
      {/* Template Section End */}
    </>
  );
};

export default Home;
