import axios from 'axios';

// For the time beinng we are using same pattern for both production and local,
// latter on we can add conditional logic depenedening on the environment
// therefore removing conditional logic.
let apiKey = process.env.REACT_APP_API_KEY;
let baseURL = process.env.REACT_APP_API_BASE_URL;

// if (process.env.NODE_ENV !== "production") {
//   apiKey = process.env.REACT_APP_API_KEY;

// } else {
//   apiKey = process.env.API_KEY;
// } 
export const axiosInstance = axios.create({
  baseURL: baseURL || 'https://api.crunchcraft.com',
  headers: { 'api-security-key': apiKey,  
            'Content-Type': "application/x-www-form-urlencoded",
          },

});
