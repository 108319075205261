import { Checkbox, Form, Row } from 'antd';
import 'antd/dist/antd.css';
import React, { useState, useEffect, useRef } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import 'react-intl-tel-input/dist/main.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { API_URLS } from '../api/apiUrls';
import { axiosInstance } from '../api/serviceApi';
import {
  branding,
  brandingWhite,
  digitalmarketing,
  digitalmarketingWhite,
  Ecommerce,
  EcommerceWhite,
  mobileapp,
  mobileappWhite,
  printmedia,
  printmediaWhite,
} from '../assets/images/contact/servicesimages.js';
import logo from '../assets/images/logo/logo.png';
import { SearchSelectBox } from '../components/AntdForm/SearchSelectBox';
import TextInputField from '../components/AntdForm/TextInputField';
import { SocialInterest } from '../data/SocialData';
import { setPhoneNumber } from '../redux/actions/actions';
import PhoneTextField from './AntdForm/PhoneTextField';
import CustomPopUp from './ReactComponents/CustomPopUp';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';

const ContactForm = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { PhoneNumber } = useSelector((state) => state.Phone);

  const [Interest, setInterest] = useState([]);
  const [IsBtnDisable, setIsBtnDisable] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [IsEmailFailed, setIsEmailFailed] = useState(false);
  const [Token, setToken] = useState(false);

  console.log(Token);
  const recaptcha = useRef(null);

  const closePopUp = () => {
    setShowAlert(false);
    setLoading(false);
    history.push('/');
  };

  const onFinishFailed = (errorInfo) => {
    if (PhoneNumber.Value === null) {
      dispatch(setPhoneNumber({ Error: 'Phone Number is required!' }));
    }
  };
  const onFinish = (value) => {
    if (value) {
      setLoading(true);
    }

    var bodyFormData = new FormData();
    var imagefile = document.querySelector('#fileControl');
    // bodyFormData.append('Name', value.Name);
    // bodyFormData.append('Email', value.Email);
    // bodyFormData.append('Phone', PhoneNumber.Value);
    bodyFormData.append('ToEmail', process.env.REACT_APP_ToEmail);
    bodyFormData.append('Files', imagefile.files[0]);
    bodyFormData.append(
      'Body',
      `<br><strong>Name: </strong>${value.Name}<br><strong>Phone: </strong>${PhoneNumber.Value}<br><strong>Email: </strong>${value.Email}<br><strong>Company: </strong>${value.CompanyName}<br><strong>How did you come to know about us: </strong>${value.KnowAbout}<br><strong>Interested: </strong>${Interest}<br><strong>Message: </strong>${value.Body}`
    );
    bodyFormData.append('SendAsync', false);

    const postData = async () => {
      await axiosInstance
        .post(API_URLS.SEND_EMAIL, bodyFormData)
        .then((res) => {
          setIsBtnDisable(true);
          setLoading(false);
          setShowAlert(true);
          setIsEmailFailed(false);
          form.resetFields();
        })
        .catch((err) => {
          setLoading(false);
          setShowAlert(true);
          setIsBtnDisable(false);
          setIsEmailFailed(true);
        });
    };

    axiosInstance.interceptors.request.use(
      async (config) => {
        const token = await Token;
        if (token) config.headers['recaptcha-token'] = token;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    postData();
  };

  const handleCheckboxChange = (event) => {
    let newArray = [...Interest, event.target.value];
    if (Interest.includes(event.target.value)) {
      newArray = newArray.filter((val) => val !== event.target.value);
    }
    setInterest(newArray);
  };

  const verifyCallback = (recaptchaToken) => {
    setToken(recaptchaToken);
  };

  useEffect(() => {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY, verifyCallback);
  }, []);

  const images = [
    { id: 1, title: 'Branding', imgSrc: branding, imgSrcChecked: brandingWhite },
    { id: 2, title: 'E-Commerce', imgSrc: Ecommerce, imgSrcChecked: EcommerceWhite },
    { id: 3, title: 'Mobile App', imgSrc: mobileapp, imgSrcChecked: mobileappWhite },
    { id: 4, title: 'Print Media', imgSrc: printmedia, imgSrcChecked: printmediaWhite },
    {
      id: 5,
      title: 'Digital Marketing',
      imgSrc: digitalmarketing,
      imgSrcChecked: digitalmarketingWhite,
    },
  ];

  return (
    <div>
      {ShowAlert ? (
        IsEmailFailed ? (
          <CustomPopUp
            show={ShowAlert}
            onHide={closePopUp}
            variant="danger"
            title="Oh Snap!"
            body="There are some errors while sending your response. Please try again later."
          />
        ) : (
          <CustomPopUp
            show={ShowAlert}
            onHide={closePopUp}
            btntype="btn-green"
            variant="success"
            title="Thank you!"
            body="Your message has been sent successfully. We will contact you soon."
          />
        )
      ) : null}

      <section className="contact-page">
        <div className="mb-5">
          <Link className="nav-brand-text navbar-brand" to="/">
            <img src={logo} alt="logo img" />
          </Link>

          <button type="button" className="cancel-btn" onClick={() => history.goBack()}>
            <FaTimes />
          </button>
        </div>

        <Form onFinish={onFinish} form={form} onFinishFailed={onFinishFailed}>
          <div className="row">
            <div className="col-md-4">
              <TextInputField
                label="Full Name"
                name="Name"
                type="text"
                requiredError="Full Name is required!"
              />
            </div>
            <div className="col-md-4">
              <TextInputField
                label="Email"
                name="Email"
                type="email"
                requiredError="Email is required!"
              />
            </div>
            <div className="col-md-4">
              <PhoneTextField
                label="Phone Number"
                name="Phone"
                type="number"
                value={PhoneNumber.Value}
                requiredError="Phone Number is required!"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <TextInputField
                label="Company Name"
                name="CompanyName"
                type="text"
                requiredError="Company Name is required!"
              />
            </div>
            <div className="col-md-4">
              <SearchSelectBox
                label="How did you come to know about us?"
                name="KnowAbout"
                options={SocialInterest}
                requiredError="Please select at least one option!"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Form.Item
                label="I'm interested in"
                name="Interested"
                rules={[
                  {
                    required: true,
                    message: 'Please select at least one option!',
                  },
                ]}>
                <div className="checkbox-div">
                  <Checkbox.Group>
                    <Row>
                      {images.map((item, key) => (
                        <Checkbox key={key} value={item.title} onChange={handleCheckboxChange}>
                          <div className="custom-checkbox">
                            <img className="img-unchecked" src={item.imgSrc} alt={item.title} />
                            <img
                              className="img-checked"
                              src={item.imgSrcChecked}
                              alt={item.title}
                            />
                          </div>
                          <div className="icon-heading">{item.title}</div>
                        </Checkbox>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <TextInputField
                label="Body"
                name="Body"
                type="text"
                requiredError="Message is required!"
              />
            </div>
          </div>
          <div>
            <input type="file" id="fileControl" />
          </div>
          <div className="mt-3">
            <ReCaptcha
              ref={recaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              action="login"
              verifyCallback={verifyCallback}
            />
          </div>
          <div className="row">
            <div className="col-md-2">
              <Button
                className="form-control mt-1 btn-green"
                variant="success"
                type="submit"
                disabled={IsBtnDisable}>
                {Loading ? (
                  <span>
                    <span>Submitting...</span>
                    <Spinner
                      className="ml-3"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  </span>
                ) : (
                  <span className="visually-hidden">Submit</span>
                )}
              </Button>
            </div>
          </div>
        </Form>
      </section>
    </div>
  );
};

export default ContactForm;
