import { createContext, useContext, useState } from "react";
import Categories from "../data/TemplateData";
import { ActiveContext } from "../context/ActiveContext";
import { useEffect } from "react";

export const CurrentCatContext = createContext(null);

export const CurrentCatProvider = (props) => {
  const { setActiveBtn } = useContext(ActiveContext);

  const filterOne = Categories.filter((ele) => {
    return ele.Name === "Fashion";
  });

  const [currCat, setCurrCat] = useState(filterOne);

  useEffect(() => {
    setActiveBtn("Fashion");
  }, [setActiveBtn]);

  return (
    <CurrentCatContext.Provider value={{ currCat, setCurrCat }}>
      {props.children}
    </CurrentCatContext.Provider>
  );
};
