import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaPlayCircle,
} from "react-icons/fa";

const SocialData = [
  {
    Name: "Facebook",
    Icon: <FaFacebookF />,
    link: "https://www.facebook.com/",
    id: 1,
  },
  {
    Name: "Twitter",
    Icon: <FaTwitter />,
    link: "https://www.twitter.com/",
    id: 2,
  },
  {
    Name: "Instagram",
    Icon: <FaInstagram />,
    link: "https://www.instagram.com/",
    id: 3,
  },
  {
    Name: "Youtube",
    Icon: <FaPlayCircle />,
    link: "https://www.youtube.com/",
    id: 4,
  },
];

export default SocialData;

export const SocialInterest = [
  "Facebook",
  "LinkedIn",
  "Instagram",
  "Friend",
  "Others",
];
