import React from "react";
import featureData from "../data/FeatureData";
import { featureMainImg } from "../assets/images/feature/featureimages.js";
import FeatureWhite from "./Features/FeatureWhite";
import FeatureGrey from "./Features/FeatureGrey";
import FeatureAssistance from "./Features/FeatureAssistance";
import { Link } from "react-router-dom";

const Feature = () => {
  const featureMap = featureData.map((evenItem, index) => {
    if (index % 2 === 0) {
      return (
        <section
          key={index}
          id={evenItem.LinkId}
          className="section-padding white-bg mob-text-center"
        >
          <FeatureWhite
            name={featureData[index].Name}
            desc={featureData[index].Description}
            detail={featureData[index].Detail}
            image={featureData[index].Image}
          />
        </section>
      );
    } else {
      //If condition for Feature Six Start
      if (evenItem.LinkId === "featureSix") {
        return (
          <section
            key={index}
            id={evenItem.LinkId}
            className="section-padding grey-bg mob-text-center"
          >
            <FeatureAssistance
              name={featureData[index].Name}
              desc={featureData[index].Description}
              detail={featureData[index].Detail}
            />
          </section>
        );
      }
      //If condition for Feature Six End
      return (
        <section
          key={index}
          id={evenItem.LinkId}
          className="section-padding grey-bg mob-text-center"
        >
          <FeatureGrey
            name={featureData[index].Name}
            desc={featureData[index].Description}
            detail={featureData[index].Detail}
            image={featureData[index].Image}
          />
        </section>
      );
    }
  });

  return (
    <>
      {/* Feature Home Section */}
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="banner-inner">
                <div className="banner-left">
                  <h1>All e-commerce features in one place</h1>
                  <div>
                    <p>
                    The most efficient e-commerce solution for your business: set up, market, 
                    convert sales, expand, and take informed decisions with customized reports 
                    and insights. Manage payments, shipping, and online payments. 
                    Handling your business has never been this easy!
                    </p>
                  </div>
                  <div>
                    <Link to="/ContactUs">
                      <button className="btn btn-green">View Plans</button>
                    </Link>
                    {/* <button className="btn btn-white ml-3">
                        Start free trail
                      </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-5">
              <div className="">
                <img
                  src={featureMainImg}
                  alt="feature main"
                  className="width-full img-padding"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Feature Home Section End */}
      <section className="green-bg feature-menu-section">
        <div className="text-center">
          {featureData.map((val, ind) => {
            return (
              <span key={ind}>
                <a href={val.id} className="btn feature-btn">
                  {val.Name}
                </a>
              </span>
            );
          })}
        </div>
      </section>

      {/* Feature Sections */}

      {featureMap}

      {/* Feature Sections End */}
    </>
  );
};

export default Feature;
