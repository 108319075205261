import React, { useContext } from "react";
import { CurrentCatContext } from "../../context/CurrentCatContext";

const CategoryDetail = (props) => {
  const { currCat } = useContext(CurrentCatContext);

  return (
    <>
      <div className="row template-middle-row">
        {currCat.map((item, ind) => {
          const { Detail } = item;
          return (
            <React.Fragment key={ind}>
              {Detail.map((ele, index) => {
                const { Heading, SubHeading, Image } = ele;

                return (
                  <div key={index} className="col-md-3 col-width-half">
                    <div className="template-data">
                      <img
                        src={Image}
                        alt="Img alt"
                        className="width-full category-img"
                      />

                      <div className="template-head-main">
                        <div className="heading-md">{Heading}</div>
                        <div className="heading-sm">{SubHeading}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default CategoryDetail;
