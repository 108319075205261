import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { ActiveProvider } from './context/ActiveContext';
import { CategoryProvider } from './context/CategoryContext';
import { CurrentCatProvider } from './context/CurrentCatContext';
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <ActiveProvider>
      <CategoryProvider>
        <CurrentCatProvider>
          <App />
        </CurrentCatProvider>
      </CategoryProvider>
    </ActiveProvider>
  </Provider>,
  document.getElementById('root')
);
