import { createContext, useState } from "react";

export const ActiveContext = createContext(null);

export const ActiveProvider = (props) => {
  const [activeBtn, setActiveBtn] = useState();

  return (
    <ActiveContext.Provider value={{ activeBtn, setActiveBtn }}>
      {props.children}
    </ActiveContext.Provider>
  );
};
