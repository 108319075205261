import React from "react";
import { FaCircle } from "react-icons/fa";

const FeatureWhite = (props) => {
  return (
    <>
      <div className="container">
        <div className="row justify-cntr-mob">
          <div className="col-sm-10 col-lg-5">
            <h1 className="head-feature-text">{props.name}</h1>
            <p className="feature-para">{props.desc}</p>
          </div>
        </div>
        <div className="row justify-cntr-mob">
          <div className="col-md-8">
            <div className="feature-detail">
              {props.detail.map((detail, ind) => {
                return (
                  <span key={ind}>
                    <div className="head-sm-text">
                      <span className="circle-icon">
                        <FaCircle />
                      </span>
                      {detail.Heading}
                    </div>
                    <div>{detail.HeadDescription}</div>
                  </span>
                );
              })}
            </div>
          </div>
          <div className="col-md-4 display-col-mob">
            <div className="feature-img">
              <img src={props.image} alt="feature img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureWhite;
