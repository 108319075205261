import { actionTypes } from './actionTypes';

export const sendEmail = (data) => {
  return {
    type: actionTypes.SEND_EMAIL,
    payload: data,
  };
};

export const setPhoneNumber = (data) => {
  return {
    type: actionTypes.PHONE_NUMBER_STATE,
    payload: data,
  };
};
